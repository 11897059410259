<template>
  <v-bottom-sheet
    class="ft font-weight-medium"
    inset
    v-model="state"
    max-width="700px"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        <div class=" d-flex flex-row flex-grow-1">
          <div class="d-flex flex-column">
            <v-avatar color="primary" size="60">
              <span
                class=" text-uppercase white--text ft font-weight-bold font-size-lg"
                >{{ name.charAt(0) }}</span
              >
            </v-avatar>
          </div>
          <div class="d-flex flex-column flex-grow-1 pl-1">
            <div class="d-flex flex-row flex-grow-1 mt-1">
              <i class="material-icons-outlined">notifications</i
              ><span
                class="ft font-weight-medium font-size-md text-uppercase pl-2 mt-n1"
                >SMS/Push Notification Preferences</span
              >
            </div>
            <i class="ft pl-1 font-weight-medium font-size-sm mt-n1"
              >Set which notifications (sms and push notifications) user should
              receive
            </i>
          </div>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="d-flex flex-row flex-grow-1 flex-wrap mt-n1">
          <div class="d-flex flex-column flex-grow-1 mt-n1">
            <v-checkbox v-model="preferences.fuelSalesAlert" dense hide-details>
              <template #label>
                <span class="ft black--text font-size-md text-capitalize"
                  >Fuel Sales</span
                >
              </template>
            </v-checkbox>
            <div
              class="ft font-weight-medium"
              style="font-size: 14px;font-style:italic;"
            >
              <q
                >Receive push notifications, sms alert on recording of daily
                fuel sales</q
              >
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 mt-n1">
            <v-checkbox dense v-model="preferences.lubeSalesAlert" hide-details>
              <template #label>
                <span class="ft black--text font-size-md text-capitalize"
                  >Lube Sales</span
                >
              </template>
            </v-checkbox>
            <div
              class="ft font-weight-medium"
              style="font-size: 14px;font-style:italic;"
            >
              <q
                >Receive push notifications, sms alert on recording of daily
                lube sales</q
              >
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 mt-n1">
            <v-checkbox
              v-model="preferences.shopSalesAlert"
              dense
              hide-details
              class="font  text-sm"
            >
              <template #label>
                <span class="ft black--text font-size-md text-capitalize"
                  >Shop Sales</span
                >
              </template>
            </v-checkbox>
            <div
              class="ft font-weight-medium"
              style="font-size: 14px;font-style:italic;"
            >
              <q
                >Receive push notifications, sms alert on recording of daily
                shop sales</q
              >
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 mt-n1">
            <v-checkbox v-model="preferences.stocksAlert" dense hide-details>
              <template #label>
                <span class="ft black--text font-size-md text-capitalize"
                  >Stocks Level</span
                >
              </template>
            </v-checkbox>
            <div
              class="ft font-weight-medium"
              style="font-size: 14px;font-style:italic;"
            >
              <q
                >Receive push notifications, sms alert on daily stock levels for
                both products/fuels and lubes</q
              >
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 mt-n1">
            <v-checkbox
              v-model="preferences.variationsAlert"
              dense
              hide-details
            >
              <template #label>
                <span class="ft black--text font-size-md text-capitalize"
                  >Fuels Variations
                </span>
              </template>
            </v-checkbox>
            <div
              class="ft font-weight-medium"
              style="font-size: 14px;font-style:italic;"
            >
              <q
                >Receive push notifications, sms alert including fuel variations
                and fuels sold</q
              >
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">
          <span class="ft text-uppercase">Close</span>
        </v-btn>
        <v-btn
          @click="savePreference"
          :loading="loading"
          :disabled="loading || !this.userId"
          color="primary"
        >
          <span class="ft text-uppercase">Save Preference</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "NotificationPreferenceBottomSheet",
  props: {
    state: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  data: () => ({
    userId: "",
    name: "",

    preferences: {
      fuelSalesAlert: false,
      lubeSalesAlert: false,
      shopSalesAlert: false,
      stocksAlert: false,
      variationsAlert: false
    }
  }),
  watch: {
    details(payload) {
      this.userId = payload.id;
      this.name = payload.name;
      const { userSMSNotificationPreference } = payload;
      this.preferences = userSMSNotificationPreference
        ? userSMSNotificationPreference
        : {
            fuelSalesAlert: false,
            lubeSalesAlert: false,
            shopSalesAlert: false,
            stocksAlert: false,
            variationsAlert: false
          };
    }
  },
  methods: {
    savePreference() {
      this.$emit("savePreference", {
        id: this.userId,
        preferences: this.preferences
      });
    },
    close() {
      this.$emit("actions", "generate");
    }
  }
};
</script>
